export const columns_config = () => {
	return [{
			name: "lgsCode",
			nameEn: "lgsCode",
			data: 'lgsCode',
			// editor: 'select',
			// selectOptions: []
			type: 'text',
			// type: 'dropdown',
			// source: []
			// width: 200,
		},
		// {
		// 	name: "Zones",
		// 	nameEn: "Zones",
		// 	data: 'Zones',
		// 	type: 'text',
		// 	// type: 'dropdown',
		// 	// source: []
		// },
		{
			name: "Zone 2",
			nameEn: "Zone 2",
			data: 'zone2Price',
			type: 'text',
		},
		{
			name: "Zone 3",
			nameEn: "Zone 3",
			data: 'zone3Price',
			type: 'text',
		},
		{
			name: "Zone 4",
			nameEn: "Zone 4",
			data: 'zone4Price',
			type: 'text',
		},
		{
			name: "Zone 5",
			nameEn: "Zone 5",
			data: 'zone5Price',
			type: 'text',
		},
		{
			name: "Zone 6",
			nameEn: "Zone 6",
			data: 'zone6Price',
			type: 'text',
		},
		{
			name: "Zone 7",
			nameEn: "Zone 7",
			data: 'zone7Price',
			type: 'text',
		},
		{
			name: "Zone 8",
			nameEn: "Zone 8",
			data: 'zone8Price',
			type: 'text',
		},
		{
			name: "Zone 9",
			nameEn: "Zone 9",
			data: 'zone9Price',
			type: 'text',
		},
		{
			name: "Zone 10",
			nameEn: "Zone 10",
			data: 'zone10Price',
			type: 'text',
		},
		{
			name: "Zone 11",
			nameEn: "Zone 11",
			data: 'zone11Price',
			type: 'text',
		},
		{
			name: "Zone 12",
			nameEn: "Zone 12",
			data: 'zone12Price',
			type: 'text',
		},
		{
			name: "费用类型",
			nameEn: "feeType",
			data: 'feeType',
			type: 'text',
		},
		{
			name: "是否支持",
			nameEn: "isSupport",
			data: 'isSupport',
			// type: 'text',
			type: 'dropdown',
			source: ['0','1']
		},
		{
			name: "是否收取服务费",
			nameEn: "isAddSf",
			data: 'isAddSf',
			// type: 'text',
			type: 'dropdown',
			source: ['0','1']
		},
		{
			name: "对账属性",
			nameEn: "billAtrr",
			data: 'billAtrr',
			type: 'text',
		},
		{
			name: "备注",
			nameEn: "remark",
			data: 'remark',
			type: 'text',
		},
		// {
		// 	name: "ID",
		// 	data: 'id',
		// 	type: 'text',
		// 	readOnly: true
		// },
	];
}


export const nestedHeadersTop_config = () => {
	return [
		// [{
		// 		label: '发货信息',
		// 		colspan: 21
		// 	},
		// 	{
		// 		label: '追踪信息(自供面单时必填)tracking Info',
		// 		colspan: 2
		// 	},
		// 	{
		// 		label: '保险和签名服务(只用于平台出单)',
		// 		colspan: 2
		// 	},
		// 	'',
		// 	'',
		// ]
	];
}